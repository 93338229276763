body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Book spinner */
@keyframes book-animation {
  0% { transform: translateY(0); }
  40% { transform: translateY(-16px); }
  80% { transform: translateY(0); }
  100% { transform: translateY(0); }
}

.loader {
  background-color: #DEE4E6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.book-animation__book {
  animation: book-animation 0.8s ease infinite;
}

.book-animation__book-two {
  animation-delay: 0.02s;
}

.book-animation__book-three {
  animation-delay: 0.05s;
}

.media-search {
  padding: 8px;
  border-radius: 16px;
  background-color: #125C76;
  color: #FFFFFF;
  border: 1px solid #08526b;
  width: 50%;
}

.media-search::placeholder {
  color: #CCCCCC;
}

/* iOS PWA installation banner */
#iOSPWA-overlay {
  background-color: rgba(0, 0, 0, .8);
  left: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity .2s ease-in;
  width: 100vw;
  z-index: 999999;
  pointer-events: none;
  touch-action: none;
  display: none;
}


#iOSPWA-overlay.visible {
  opacity: 1;
  display: block;
}

#iOSPWA-container {
  backdrop-filter: blur(10px);
  background:hsla(0, 0%, 98%, .8);
  border-radius: 10px;
  bottom: 0;
  color: #000;
  filter: brightness(1.1);
  left: 0;
  margin: 0 8px 10px;
  overflow: hidden;
  position: fixed;
  transform: translateY(calc(100% + 10px));
  transition: transform .4s cubic-bezier(.4, .24, .3, 1);
  width: calc(100vw - 16px);
  z-index: 999999;
}

#iOSPWA-container.visible {
  transform: translateY(0);
  display: block;
}

#iOSPWA-container.hidden {
  pointer-events: none;
  touch-action: none;
}

@media (prefers-color-scheme: dark) {
  #iOSPWA-container {
    background: rgba(120, 120, 128, .32);
  }
}

.iOSPWA-header {
  border: 0;
  border-bottom: .5px solid rgba(0, 0, 0, .1);
  border-right-width: .5px;
  border-top-width: .5px;
  padding: 13px 16px;
  border-color:rgba(60, 60, 67, .29);
}

.iOSPWA-header-title {
  color:#000;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.125;
  margin: 0;
  padding: 0;
}

.iOSPWA-header-cancel {
  color:#007aff;
  font-size: 16px;
  padding: 0;
  margin: 5px 0 0 0;
  border: 0;
  background: transparent;
}

.iOSPWA-body {
  display: flex;
  width: 100%;
}

.iOSPWA-body-description {
  border: 0;
  border-bottom: .5px solid rgba(0, 0, 0, .1);
  border-right-width: .5px;
  border-top-width: .5px;
  color: inherit;
  margin: 0 16px;
  padding: 16px;
  width: 100%;
  border-color:rgba(60, 60, 67, .29);
}

.iOSPWA-body-text {
  color:rgba(60, 60, 67, .6);
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 13px;
  line-height: 17px;
  margin: 0;
  padding: 0;
  font-weight:600;
}

.iOSPWA-steps {
  color: inherit;
  margin: 0 16px;
  padding: 16px;
}

.iOSPWA-steps__step {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 16px;
}

.iOSPWA-steps__step:last-of-type {
  margin-bottom: 0;
}

.iOSPWA-icon {
  flex: 0 0 auto;
  height: 30px;
  margin-right: 32px;
  width: 25px;
}

.iOSPWA-icon__home {
  color: #58595b;
  fill: #58595b;
}

.iOSPWA-icon__share {
  color: #007aff;
  fill: #007aff;
}

@media (prefers-color-scheme: dark) {
  #iOSPWA-overlay {
    background: rgba(0, 0, 0, .6);
    color: rgba(235, 235, 245, .6)
  }

  .iOSPWA-header {
    border-color: rgba(84, 84, 88, .6);
  }

  .iOSPWA-header-title {
    color: #fff;
  }

  .iOSPWA-header-cancel {
    color: #0984ff;
  }

  .iOSPWA-body-description {
    border-color: rgba(84, 84, 88, .6);
  }

  .iOSPWA-body-text {
    border-color: rgba(235, 235, 245, .6);
    color: rgba(235, 235, 245, .6);
  }

  .iOSPWA-icon__home {
    color: #fff;
    fill: #fff;
  }

  .iOSPWA-icon__share {
    color: #0984ff;
    fill: #007aff;
  }
}
